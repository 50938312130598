// WebKit-style focus
.tab-focus() {
  outline: 0;
}

.reset-link-text-decoration(@text-decoration:none) {
  text-decoration: @text-decoration;

  &:hover,
  &:focus,
  &:active {
    text-decoration: @text-decoration;
  }
}

.text-emphasis-variant(@color) {
  color: @color;

  a&:hover,
  a&:focus {
    color: darken(@color, 10%);
  }
}

// Text overflow
// Requires inline-block or block for proper styling

.ellipsis-basic() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis(@substract:0) {
  .ellipsis-basic();

  width: 100% - @substract;
}

//drop-shadow
.drop-shadow(@shadow) {
  filter: drop-shadow(@shadow);
  // IE don't support `drop-shadow`,replace it with `box-shadow`.
  // FIXME duplicated shadows
  .ie-box-shadow(@shadow);
}

.set-translate-transition(@translateX:0,@translateY:0) {
  transform: translate(0, 0);

  &.rs-anim-in {
    transform: translate(@translateX, @translateY);
  }
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix() {
  &::before,
  &::after {
    content: ' '; // 1
    display: table; // 2
  }

  &::after {
    clear: both;
  }
}

// Single side border-radius
.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

.pointer-event-none() {
  // Make sure events through
  pointer-events: none;
  // Fixed in safari, after multiple clicks , the loader still has not disappeared.
  .safari-visibility(hidden);
}

.padding-vertical(@padding-vertical) {
  padding-top: @padding-vertical;
  padding-bottom: @padding-vertical;
}

.padding-horizontal(@padding-vertical) {
  padding-left: @padding-vertical;
  padding-right: @padding-vertical;
}

.icon-bolder(@width:2px) {
  stroke: currentColor;
  stroke-width: @width;
  stroke-linecap: round;
}

// Useful when adding focus ring to an element
.with-focus-ring() {
  &:focus {
    .focus-ring();
  }
}

// Useful when appending the ring into an existing :focus rule
.focus-ring() {
  box-shadow: var(--rs-state-focus-shadow);
}

.focus-ring(slim) {
  box-shadow: var(--rs-state-focus-shadow-slim);
}

.focus-ring(inset) {
  box-shadow: inset var(--rs-state-focus-shadow);
}

.focus-ring(slim-inset) {
  box-shadow: inset var(--rs-state-focus-shadow-slim);
}

.focus-ring(outline) {
  outline: var(--rs-state-focus-outline);
}

// Removes the focus ring
.unset-focus-ring() {
  box-shadow: none;
  outline: none;
}
