@import '../../styles/common';

// NavBar
// --------------------------------------------------

.rs-navbar {
  .clearfix();
}

.rs-navbar-header {
  float: left;
  display: inline-block;
  height: @navbar-height;
  color: inherit;

  a {
    color: inherit;
  }
}

.rs-navbar-brand {
  &:extend(.rs-navbar-header); // TODO deprecate .rs-navbar-header

  padding: 18px 20px;
}

// Common
.rs-navbar-nav {
  float: left;

  &.rs-navbar-right {
    float: right;
  }

  &:focus {
    .rs-navbar-item:focus-visible,
    .rs-navbar-item.rs-navbar-item-focus,
    .rs-dropdown .rs-dropdown-toggle:focus-visible,
    .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
      z-index: 1;

      .focus-ring();

      .high-contrast-mode({
        .focus-ring(slim-inset);
      });
    }
  }
}

// Nav item content
.rs-navbar-item {
  // Reset padding
  padding: @navbar-item-padding-y @navbar-item-padding-x;
  height: @navbar-height;
  border-radius: 0;
  color: inherit;
  float: left;
  background-color: transparent;
  position: relative;
  overflow: hidden;
}

// Focus style
.rs-navbar-brand,
.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown-item {
  outline: 0;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:focus-visible {
    .focus-ring();

    .high-contrast-mode({
      .focus-ring(slim-inset);
    });

    z-index: 2;
  }
}

.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown {
  margin: 0 !important;
}

// DropDown
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  &:extend(.rs-navbar-item);

  padding-right: @navbar-item-padding-x+ @dropdown-caret-width+ @dropdown-caret-padding;

  .high-contrast-mode({
    border: none;

    &:focus-visible {
      .focus-ring(slim-inset);
    }
  });

  .rs-dropdown-toggle-caret {
    top: @navbar-item-padding-y;
    right: @navbar-item-padding-x;
  }
}

.rs-navbar-item.rs-navbar-item-active {
  .high-contrast-mode({
    text-decoration: underline;
  });
}

// Default Navbar
.rs-navbar-default {
  background-color: var(--rs-navbar-default-bg);
  color: var(--rs-navbar-default-text);

  .rs-navbar-item:hover,
  .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
    background-color: var(--rs-navbar-default-hover-bg);
    color: var(--rs-navbar-default-hover-text);
  }

  .rs-navbar-item.rs-navbar-item-active,
  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: var(--rs-navbar-default-selected-text);
  }
}

// Inverse Navbar
.rs-navbar-inverse {
  background-color: var(--rs-navbar-inverse-bg);
  color: var(--rs-navbar-inverse-text);

  .rs-navbar-item,
  .rs-dropdown .rs-dropdown-toggle {
    &:hover,
    &:focus {
      background-color: var(--rs-navbar-inverse-hover-bg);
      color: var(--rs-navbar-inverse-hover-text);
    }
  }

  // Active
  .rs-navbar-item.rs-navbar-item-active,
  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    background-color: var(--rs-navbar-inverse-selected-bg);

    .high-contrast-mode({
      color: var(--rs-navbar-inverse-selected-text);
    });
  }
}

// Subtle Navbar
.rs-navbar-subtle {
  background-color: transparent;
  color: var(--rs-navbar-subtle-text);

  .rs-navbar-item,
  .rs-dropdown .rs-dropdown-toggle {
    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--rs-navbar-subtle-hover-text);
    }
  }

  // Active
  .rs-navbar-item.rs-navbar-item-active,
  .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: var(--rs-navbar-subtle-selected-text);
  }
}
