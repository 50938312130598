@import '../../styles/common';
@import '../../Picker/styles/mixin';
@import '../../TreePicker/styles/mixin';

// **Check Tree Picker
// **----------------------

.rs-check-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  flex: 1 1 auto;

  &.rs-check-tree-virtualized {
    overflow: hidden;

    /* stylelint-disable-next-line */ // **This class name provide by Virtualized.
    .ReactVirtualized__Grid.ReactVirtualized__List {
      padding: 0 @picker-menu-padding @picker-menu-padding 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.rs-check-tree-node {
  position: relative;
  font-size: @picker-tree-node-font-size;
  line-height: @picker-tree-node-line-height;

  &-label {
    .rs-check-item {
      display: inline-block;

      .rs-picker-menu & {
        display: block;
      }
    }

    &:focus .rs-check-item .rs-checkbox-checker > label {
      .picker-item-hover();
    }

    .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
      .picker-item-active();
    }

    .rs-check-item .rs-checkbox-checker > label {
      text-align: left;
      position: relative;
      margin: 0;
      //padding-left: 16px;
      padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
      //text gap + checkbox space
      padding-left: 58px; // 10px + 36px + 12px

      &::before {
        content: '';
        position: absolute;
        width: (@picker-tree-arrow-down-width+ @picker-tree-arrow-down-gap + 30px); // checkbox-spacing = 30
        height: 100%;
        top: 0;
        margin-left: -58px; // 10px + 36px + 12px
      }

      .rs-checkbox-wrapper {
        left: (@checkbox-sense-width + 10px);
      }

      // Only has the first level
      .without-children & {
        padding-left: 34px; //text gap + checkbox space

        &::before {
          width: 28px;
          margin-left: -36px;
        }

        .rs-checkbox-wrapper {
          left: 0;
        }
      }
    }
  }

  // Uncheckable state
  &-all-uncheckable > .rs-check-tree-node-label {
    .rs-check-item .rs-checkbox-checker > label {
      padding-left: 22px; // 10px + 12px

      &::before {
        width: 14px;
        margin-left: 0;
        left: 0;
      }
    }
  }
}

// expand icon
.rs-check-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .rs-check-tree-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: 16px;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
    transform-origin: 1.5px 14px;
    margin-top: 6px;
    user-select: none;

    // expand children tree
    .rs-check-tree-open > .rs-check-tree-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .rs-check-tree-node-expanded {
    transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }

  > .rs-check-tree-node-custom-icon,
  > .rs-check-tree-node-loading-icon {
    .node-item-prepend-icon();
  }
}

// node children
.rs-check-tree-node-children {
  > .rs-check-tree-children {
    display: none;

    .rs-check-tree-open& {
      display: block;
    }
  }
}

.rs-picker-menu {
  display: flex;
  flex-direction: column;

  &.rs-check-tree-menu {
    padding-top: @picker-menu-padding;

    .rs-picker-search-bar {
      padding-top: 0;
    }
  }

  .rs-check-tree {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &.rs-check-tree-virtualized {
      padding: 0;
    }

    &-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
      .ellipsis();

      display: inline-block;
      vertical-align: top;
    }
  }
}

/* rtl:begin:ignore */

[dir='rtl'] .rs-check-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
}

/* stylelint-disable-next-line */
.rs-check-tree {
  /* stylelint-disable-next-line */
  &-node > &-node-label .rs-check-item .rs-checkbox-checker > label::before {
    /* stylelint-disable-next-line */
    [dir='rtl'] .ReactVirtualized__Grid__innerScrollContainer & {
      padding-right: inherit;
      right: 46px;
    }
  }
}

/* rtl:end:ignore */
