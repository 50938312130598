@import '../../styles/common';
@import '../../styles/mixins/listbox';
@import '../../Picker/styles/mixin';
@import 'mixin';

@custom-picker-tree-node-padding-vertical: @picker-tree-node-padding-vertical - 2px;
// Tree Picker
// ----------------------

.rs-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  flex: 1 1 auto;

  &-drag-node-mover {
    position: absolute;
    top: -1000px;
    color: var(--rs-text-primary);
    background-color: var(--rs-bg-overlay);
    display: inline-block;
    margin: 0;
    padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
    border-radius: 6px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    z-index: 1060;
    cursor: move;
  }

  &.rs-tree-virtualized {
    overflow: hidden;

    /* stylelint-disable-next-line */ // This class name provide by Virtualized.
    .ReactVirtualized__Grid.ReactVirtualized__List {
      padding: 0 @picker-menu-padding @picker-menu-padding 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.rs-tree-node {
  position: relative;
  // Clear host whitespace
  font-size: 0;
  text-align: left;
  margin: 0 0 4px 0;

  > .rs-tree-node-label {
    position: relative;
    margin: 0;
    //text gap
    padding-left: (@picker-tree-arrow-down-width + 8px);
    display: inline-block;
    cursor: pointer;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;

    > .rs-tree-node-label-content {
      padding: @custom-picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal
        @custom-picker-tree-node-padding-vertical @picker-tree-arrow-down-gap;
      display: inline-block;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:hover,
      &:focus,
      &&-focus {
        .listbox-option-active();
      }
    }
  }

  &&-active > .rs-tree-node-label > .rs-tree-node-label-content {
    .picker-item-active();

    color: var(--rs-text-link);
    background-color: var(--rs-listbox-option-selected-bg);
  }

  &-drag-over {
    background-color: var(--rs-listbox-option-hover-bg);
  }

  &-dragging {
    outline: 1px dashed var(--rs-primary-500);
  }

  &-drag-over-top {
    &.rs-tree-node-label-content {
      border-top: 2px solid var(--rs-text-link) !important;

      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 12px;
        width: 0;
        height: 0;
        border-left: 6px solid var(--rs-text-link);
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  &-drag-over-bottom {
    &.rs-tree-node-label-content {
      border-bottom: 2px solid var(--rs-text-link) !important;

      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 12px;
        width: 0;
        height: 0;
        border-left: 6px solid var(--rs-text-link);
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  &-drag-disabled {
    &.rs-tree-node-label-content {
      cursor: not-allowed;
    }
  }

  &-disabled {
    color: var(--rs-listbox-option-disabled-text);
    cursor: @cursor-disabled;

    > .rs-tree-node-label > .rs-tree-node-label-content {
      cursor: @cursor-disabled;

      &,
      &:hover {
        background-color: transparent;
      }
    }
  }

  &-disabled&-active > .rs-tree-node-label > .rs-tree-node-label-content {
    &,
    &:hover {
      color: var(--rs-listbox-option-disabled-selected-text);
    }
  }

  &:not(&-disabled):focus > .rs-tree-node-label > .rs-tree-node-label-content {
    background-color: var(--rs-listbox-option-selected-bg);
  }

  &:not(&-disabled) > .rs-tree-node-label:focus > .rs-tree-node-label-content {
    background-color: var(--rs-listbox-option-selected-bg);
  }
}

// expand icon
.rs-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .rs-tree-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: 16px;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
    transform-origin: 1.5px 14px;
    margin-top: 6px;
    user-select: none;

    // expand children tree
    .rs-tree-open > .rs-tree-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .rs-tree-node-expanded {
    transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }

  > .rs-tree-node-custom-icon,
  > .rs-tree-node-loading-icon {
    .node-item-prepend-icon();
  }
}

// node children
.rs-tree-node-children {
  > .rs-tree-children {
    display: none;

    .rs-tree-open& {
      display: block;
    }
  }
}

.rs-picker-menu {
  display: flex;
  flex-direction: column;

  &.rs-tree-menu {
    padding-top: @picker-menu-padding;

    .rs-picker-search-bar {
      padding-top: 0;
    }
  }

  .rs-tree {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &.rs-tree-virtualized {
      padding: 0;
    }

    &-node {
      margin: 0;

      > .rs-tree-node-label > .rs-tree-node-label-content {
        .ellipsis();

        display: block;
        line-height: @line-height-computed;
      }
    }
  }
}

/* rtl:begin:ignore */

[dir='rtl'] .rs-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
}

/* stylelint-disable */
[dir='rtl']
  .ReactVirtualized__Grid__innerScrollContainer
  .rs-tree-node
  > .rs-tree-node-label::before {
  display: none;
}

/* stylelint-enable */

/* rtl:end:ignore */
