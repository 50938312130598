@import '../../styles/common';
@import '../../Checkbox/styles/mixin';
@import '../../Button/styles/mixin';
@import 'mixin';

.rs-radio {
  .radio-checkbox-common();

  [type='radio']:focus ~ .rs-radio-inner::before {
    box-shadow: var(--rs-state-focus-shadow);
  }
}

.rs-radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: (@radio-sense-width * 2 + @radio-width-height);
  min-height: (@radio-sense-width * 2 + @radio-width-height);
  line-height: 1;
  position: relative;
}

.rs-radio-wrapper {
  position: absolute;
  width: @radio-width-height;
  height: @radio-width-height;
  display: inline-block;
  left: @radio-sense-width;
  top: @checkbox-sense-width;

  [type='radio'] {
    opacity: 0;
  }

  &::before,
  &::after,
  .rs-radio-inner::before,
  .rs-radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .rs-radio-inner::before {
    width: @radio-width-height;
    height: @radio-width-height;
  }

  // Ripple effect area.
  &::before {
    .radio-inner-wrapper(var(--rs-radio-checked-bg));

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .high-contrast-mode({
      transition: none;
    });

    .rs-radio-checked & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sense area(Can be clicked).
  &::after {
    top: -@radio-sense-width;
    right: -@radio-sense-width;
    bottom: -@radio-sense-width;
    left: -@radio-sense-width;
  }
}

// Out border.
.rs-radio-inner {
  &::before {
    .radio-inner-wrapper(var(--rs-radio-border));

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .high-contrast-mode({
      transition: none;
    });

    .rs-radio:hover & {
      border-color: var(--rs-radio-checked-bg);
    }

    .rs-radio.rs-radio-disabled & {
      border-color: var(--rs-radio-disabled-bg);
      background-color: var(--rs-radio-disabled-bg);
    }

    .rs-radio.rs-radio-checked & {
      border-color: var(--rs-radio-checked-bg);
      background-color: var(--rs-radio-checked-bg);
    }

    .rs-radio.rs-radio-disabled.rs-radio-checked & {
      opacity: 0.3;

      .high-contrast-mode({
        opacity: 0.5;
      });
    }
  }

  // Solid circle.
  &::after {
    width: @radio-inner-width-height;
    height: @radio-inner-width-height;
    background-color: var(--rs-radio-marker);
    margin-top: ((@radio-width-height - @radio-inner-width-height) / 2);
    margin-left: ((@radio-width-height - @radio-inner-width-height) / 2);
    border-radius: @radio-border-radius;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .high-contrast-mode({
      transition: none;
    });

    .rs-radio-checked & {
      transform: scale(1);
      opacity: 1;
    }
  }
}
