/*******************************
         Site Overrides
*******************************/

/* browsers require these rules separate */

.ui.form .error ::-webkit-input-placeholder {
  color: #CBCFD1 !important;
}
.ui.form .error ::-moz-placeholder {
  color: #CBCFD1 !important;
}
.ui.form .error :-ms-input-placeholder {
  color: #CBCFD1 !important;
}

.ui.input > input::-webkit-input-placeholder {
  color: #CBCFD1 !important;
}
.ui.input > input::-moz-placeholder {
  color: #CBCFD1 !important;
}
.ui.input > input:-ms-input-placeholder {
  color: #CBCFD1 !important;
}